import dynamic from 'next/dynamic';

export default {
    ArticlePage: dynamic(() => import('./ArticlePage')),
    GuidePage: dynamic(() => import('./GuidePage')),
    HomePage: dynamic(() => import('./HomePage')),
    EventListPage: dynamic(() => import('./EventListPage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    EventDetail: dynamic(() => import('./EventDetail')),
    CategoryPage: dynamic(() => import('./CategoryPage')),
    ObjectPage: dynamic(() => import('./ObjectPage')),
    LocalListPage: dynamic(() => import('./LocalListPage')),
    LocalProfilePage: dynamic(() => import('./LocalProfilePage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    MyListPage: dynamic(() => import('./MyListPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    NewsListPage: dynamic(() => import('./NewsListPage')),
    ContactListPage: dynamic(() => import('./ContactListPage')),
};
