import querystring from 'querystring';
import { getPage, getRedirect } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';

const isProd = process.env.NODE_ENV === 'production';

export default function CatchAllPage({ componentName, componentProps }) {
    const Component = LazyContainers[componentName];
    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }
    return <Component {...componentProps} />;
}

// For dynamic routing
export async function getServerSideProps({ req, params, res }) {
    let path = params?.path || [];
    path = path.join('/');
    const { host } = req.headers;

    const requestedUrl = new URL(req.url, `https://${host}`);

    let queryParams = requestedUrl.search;
    if (queryParams.indexOf('?') === 0) {
        queryParams = queryParams.substr(1);
    }

    // We need this global to determine the correct language of the SSR
    global.requestedUrl = requestedUrl;

    queryParams = querystring.parse(queryParams);

    // Try to serve page
    try {
        const {
            componentName,
            componentProps,
            redirect,
            httpResponse,
        } = await getPage(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                host,
            },
        });
        if (redirect) {
            const { destination, isPermanent } = redirect;
            return {
                redirect: {
                    destination: destination,
                    permanent: isPermanent,
                },
            };
        }
        if (httpResponse) {
            const { content, contentType } = httpResponse;
            res.statusCode = 200;
            res.setHeader('Content-Type', contentType);
            res.end(content);
            return { props: {} };
        }

        return { props: { componentName, componentProps } };
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        // When in development, show django error page on error
        if (!isProd && err.response.status >= 500) {
            const html = await err.response.text();
            return {
                props: {
                    componentName: 'PureHtmlPage',
                    componentProps: { html },
                },
            };
        }

        if (err.response.status >= 500) {
            throw err;
        }
    }

    // Try to serve redirect
    try {
        const redirect = await getRedirect(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                host,
            },
        });
        const { destination, isPermanent } = redirect;
        return {
            redirect: {
                destination: destination,
                permanent: isPermanent,
            },
        };
    } catch (err) {
        if (err.response.status >= 500) {
            throw err;
        }
    }

    // Serve 404 page
    return { notFound: true };
}
